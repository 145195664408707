<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="(기간)조치완료일"
            name="actionCompleteDates"
            v-model="searchParam.actionCompleteDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="IBM_TASK_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="업무"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="IBM_SUPPLE_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmSuppleStepCd"
            label="보완진행단계"
            v-model="searchParam.ibmSuppleStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="요청부서" 
            name="improveRequestDeptCd" 
            v-model="searchParam.improveRequestDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            type="search" 
            label="보완부서" 
            name="actionDeptCd" 
            v-model="searchParam.actionDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="보완필요 업무 확인"
            name="suppleFlag"
            v-model="searchParam.suppleFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="개선 유효성평가 대상 목록"
      tableId="impr01"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='actionCompleteRequestDate'">
          <span :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
            {{props.row.actionCompleteRequestDate}}
            <q-badge v-if="props.row.completeDateOver > 0
              && props.row.ibmSuppleStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" :label="$language('지연')" class="blinking" />
          </span>
          <span v-if="props.row.approvalStatusCd === 'ASC9999999'">
            👉 {{props.row.targetDate}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr',

  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteDates: [],
        ibmTaskTypeCd: null,
        ibmSuppleStepCd: null,
        improveRequestDeptCd: '',
        actionDeptCd: '',
        suppleFlag: 'N',
        actionFlag: 'D',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:90px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'suppleFlagName',
            field: 'suppleFlagName',
            label: '적합/보완',
            align: 'center',
            style: 'width:80px',
            sortable: true
          },
          {
            name: 'ibmSuppleStepName',
            field: 'ibmSuppleStepName',
            label: '보완진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'suppleRequest',
            field: 'suppleRequest',
            label: '보완요청 내용',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'suppleScheduleDate',
            field: 'suppleScheduleDate',
            label: '보완완료 요청일',
            align: 'center',
            style: 'width:170px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '보완부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'suppleCompleteDate',
            field: 'suppleCompleteDate',
            label: '보완완료일',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.supple.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '개선 상세'; 
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
